html {
  background-color: rgb(42, 4, 4);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

article {
  overflow: hidden;
  white-space: nowrap;
  width: 1200px;
  display: flex;
  margin-left: 150px;
  margin-top: 50px;
}

.wrapper {
  position: relative;
}

.marquee {
  animation: marquee 15s linear infinite;
}

ul {
  display: flex;
  list-style: none;
  color:white
}

li {
  margin: 10px;
}
li1 {
  margin: 20px;
}


a {
  margin: 20px;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;500;600;700;800&family=Poppins:wght@300&display=swap");

.video-container {
  display: grid;
  grid-template-columns: 60% 40%;
  width: 95%;
  margin: auto;
  padding: 30px 10px;
}
.video-container video {
  width: 75%;
  display: block;
  margin: auto;
}
.video-container .v2 img {
  display: block;
  margin: 30px auto;
}
@media (max-width: 900px) {
  .video-container {
    display: block;
  }

  .video-container video {
    width: 100%;
  }
  .video-container .v2 img {
    display: block;
    margin: auto;
    width: 70%;
  }
}

/* glalery...................................... */

.container1 {
  position: relative;
  width: 320px;
  margin: 100px auto 0 auto;
  perspective: 1000px;
}

.carousel {
  position: absolute;
  width: 100%;
  height: 50%;
  transform-style: preserve-3d;
  animation: rotate360 30s infinite forwards linear;
}
.carousel__face {
  position: absolute;
  width: 300px;
  height: 187px;
  top: 20px;
  left: 50px;
  right: 10px;
  background-size: cover;
  display: flex;
}

.carousel__face:nth-child(1) {
  background-image: url("./images/ballari-2019/ballari1.jpg");
  transform: rotateY(0deg) translateZ(430px);
}
.carousel__face:nth-child(2) {
  background-image: url("./images//banglore21/bangalore1.jpg");
  transform: rotateY(40deg) translateZ(430px);
}
.carousel__face:nth-child(3) {
  background-image: url("./images/Chikmagalur/chik5.jpg");
  transform: rotateY(80deg) translateZ(430px);
}
.carousel__face:nth-child(4) {
  background-image: url("./images//davanager-2019/davangere6.jpg");
  transform: rotateY(120deg) translateZ(430px);
}
.carousel__face:nth-child(5) {
  background-image: url("./images//banglore21/bangalore21\ \(5\).jpg");
  transform: rotateY(160deg) translateZ(430px);
}
.carousel__face:nth-child(6) {
  background-image: url("./images/hubali/hubali\ \(7\).jpg");
  transform: rotateY(200deg) translateZ(430px);
}
.carousel__face:nth-child(7) {
  background-image: url("./images//Mysore/mysore1.jpg");
  transform: rotateY(240deg) translateZ(430px);
}
.carousel__face:nth-child(8) {
  background-image: url("./images/davanager-2019/davangere1.jpg");
  transform: rotateY(280deg) translateZ(430px);
}
.carousel__face:nth-child(9) {
  background-image: url("./images/Shivamogga/shivamogga1.jpg");
  transform: rotateY(320deg) translateZ(430px);
}

@keyframes rotate360 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}
.footer_bottom {
  padding: 38px 0 46px;
}

.copyright_area {
  text-align: center;
}
.copyright_area p {
  text-transform: capitalize;
  line-height: 26px;
  color: #a0a0a0;
}

.copyright_area p a {
  color: #a8741a;
}
.copyright_area p a:hover {
  text-decoration: underline;
}
.footer_bottom {
  padding: 38px 0 46px;
}

.copyright_area {
  text-align: center;
}
.copyright_area p {
  text-transform: capitalize;
  line-height: 26px;
  color: #a0a0a0;
}

.copyright_area p a {
  color: #a8741a;
}
.copyright_area p a:hover {
  text-decoration: underline;
}
